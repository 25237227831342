<template>
  <div class="container">
    <div id="paypal-button"></div>
  </div>
</template>

<script>
export default {
  name: "PaypalButton",
  props: {
    idPaypal: {
      type: String,
      default: "",
    },
  },
  data: () => ({
    env: "",
    paypalClientId: "", // Store the PayPal client ID here
  }),
  computed: {
    isEnvStaging() {
      return this.env === "stag";
    },
  },
  created() {
    this.env = process.env.VUE_APP_ENV;
    // Set the appropriate PayPal client ID based on the environment
    this.paypalClientId = this.isEnvStaging
      ? "Afv-LjI_uiRi2AZgcfdu0FNBVrTcw5ZVifsy42sAxLTrAGea3mnccOL8U-hRUmOojL9ZNTsnuMrhLdq5"
      : "AYoTviexd7BKDS_iwzOTht8rpv7Wi4-3EoAAD1Ehz6P3q7dhFOGoEqFJX8tZ6Nt7X-eIOV13QvC344_N";
  },
  mounted() {
    this.$store.dispatch("loading/openDialog");
    this.$loadScript(
      `https://www.paypal.com/sdk/js?client-id=${this.paypalClientId}&vault=true&intent=subscription`
    )
      .then(() => {
        paypal
          .Buttons({
            style: {
              shape: "rect",
              color: "gold",
              layout: "horizontal",
              label: "paypal",
              tagline: false,
            },
            createSubscription: (data, actions) => {
              return actions.subscription.create({
                plan_id: this.idPaypal,
              });
            },
            onApprove: (data, actions) => {
              this.$emit("success", { subscriptionId: data.subscriptionID });
            },
            onCancel: () => {
              this.$emit("cancel");
            },
          })
          .render("#paypal-button");
      })
      .catch((e) => {
        this.$emit("error", e);
      })
      .finally(() => {
        this.$store.dispatch("loading/closeDialog");
      });
  },
};
</script>

<style scoped>
.container {
  display: flex;
  justify-content: center;
}
#paypal-button {
  height: 100px;
  width: 300px;
}
</style>
